(<template>
  <section class="calendar-navigation">
    <div class="calendar-navigation__date">
      <button class="calendar-navigation__date-btn"
              @click="$router.push(todayLink)">{{ $gettext('Today') }}</button>
      <button class="calendar-navigation__date-btn calendar-navigation__date-btn--back"
              @click="computeAndNavigateToArrowLink('prev')"></button>
      <button class="calendar-navigation__date-btn calendar-navigation__date-btn--forward"
              @click="computeAndNavigateToArrowLink('next')"></button>
      <sk-datepicker :no-description="true"
                     :preselected-value="datePickerValue"
                     :full-calendar="true"
                     :item-text="datepickerTextValue"
                     class="sk-date-picker__wrapper calendar-navigation__datepicker"
                     @changeday="goToDate" />
    </div>
    <div class="calendar-navigation__view">
      <button v-for="obj in switchers"
              :key="obj.name"
              :class="{'is-active': isSwitcherActive(obj.name)}"
              class="calendar-navigation__view-btn"
              @click="computeAndNavigateToSwitcherLink(obj.name)">{{ obj.text }}</button>
    </div>
  </section>
</template>)

<script>
  export default {
    props: {
      allowedViewTypes: {
        type: Array,
        default: () => {
          return [
            'day',
            'week',
            'month'
          ];
        }
      }
    },
    computed: {
      datePickerValue() { return this.$route.query.date ? this.$route.query.date : ''; },
      activeDateMoment() {
        const dateArr = this.$route.query.date ? this.$route.query.date.split('-') : [];
        return this.$moment().year(dateArr[0]).month(dateArr[1] - 1).date(dateArr[2]);
      },
      switchersList() {
        return [{
          name: 'day',
          text: this.$gettext('Day'),
          condition: this.allowedViewTypes.includes('day')
        }, {
          name: 'week',
          text: this.$gettext('Week'),
          condition: this.allowedViewTypes.includes('week')
        }, {
          name: 'month',
          text: this.$gettext('Month'),
          condition: this.allowedViewTypes.includes('month')
        }];
      },
      switchers() {
        return this.switchersList.filter((switcher) => switcher.condition);
      },
      datepickerTextValue() {
        let firstDay = '';
        let lastDay = '';
        let year = '';
        const dateArr = this.$route.query.date ? this.$route.query.date.split('-') : [];
        dateArr && dateArr.length ? dateArr[1] -= 1 : '';
        const dateMoment = this.$moment(dateArr);
        switch (this.$route.query.view) {
          case 'week':
            firstDay = this.$moment(dateMoment).subtract(((dateMoment.day() || 7) - 1), 'day');
            lastDay = this.$moment(firstDay).add(6, 'day');
            year = (firstDay.year() != lastDay.year() ? ('/' + firstDay.format('YYYY')) : '');
            return `${firstDay.format('DD')}-${firstDay.format('MM')}${year} - ${lastDay.format('DD')}-${lastDay.format('MM')}-${lastDay.format('YYYY')}`;
          case 'month':
            firstDay = this.$moment(dateMoment).subtract((dateMoment.date() - 1), 'day');
            return `${this.$helperData.getMonthNames(this)[firstDay.month()]} ${firstDay.year()}`;
        }
      },
      todayLink() {
        return {
          name: this.$route.name,
          query: this.getQueryParams({
            view: this.$route.query.view,
            date: this.$moment().format('YYYY-MM-DD')
          })
        };
      }
    },
    methods: {
      isSwitcherActive(type) { return this.$route.query.view == type; },
      computeAndNavigateToSwitcherLink(type) {
        this.$router.push({
          name: this.$route.name,
          query: this.getQueryParams({
            view: type,
            date: this.$route.query.date
          })
        });
      },
      goToDate(date) {
        this.$router.push({
          name: this.$route.name,
          query: this.getQueryParams({
            view: this.$route.query.view,
            date: date
          })
        });
      },
      computeAndNavigateToArrowLink(type) {
        const funcName = type == 'next' ? 'add' : 'subtract';
        let newDate = '';
        switch (this.$route.query.view) {
          case 'month':
            newDate = this.$moment(this.activeDateMoment)[funcName](1, 'month');
            break;
          case 'week':
            newDate = this.$moment(this.activeDateMoment)[funcName](1, 'week');
            break;
          case 'day':
            newDate = this.$moment(this.activeDateMoment)[funcName](1, 'day');
            break;
        }

        this.$router.push({
          name: this.$route.name,
          query: this.getQueryParams({
            view: this.$route.query.view,
            date: newDate ? newDate.format('YYYY-MM-DD') : ''
          })
        });
      },
      getQueryParams(newQueryParams = {}) {
        return {
          ...this.$route.query || {},
          ...newQueryParams
        };
      }
    }
  };
</script>

<style>
  .calendar-navigation__datepicker .sk-date-picker {
    width: auto;
    border: none;
  }

  .calendar-navigation__datepicker .sk-date-picker__value {
    width: auto;
    min-width: 100px;
    padding-right: 25px;
    background-color: transparent;
    font-weight: bold;
  }

  .calendar-navigation__datepicker .sk-date-picker__value::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 12px;
    box-sizing: border-box;
    width: 7px;
    border-top: 1px solid #000;
    transform: rotate(43deg);
  }

  .calendar-navigation__datepicker .sk-date-picker__value::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 7px;
    box-sizing: border-box;
    width: 7px;
    border-top: 1px solid #000;
    transform: rotate(-43deg);
  }

  .calendar-navigation__datepicker .is-active .sk-date-picker__value::before {
    transform: rotate(-43deg);
  }

  .calendar-navigation__datepicker .is-active .sk-date-picker__value::after {
    transform: rotate(43deg);
  }

  .calendar-navigation__datepicker .sk-date-picker__value:active {
    width: auto;
    padding-right: 25px;
    background-color: transparent;
  }

  .calendar-navigation__datepicker .sk-date-picker__value:focus {
    outline: auto;
  }
</style>

<style scoped>
  .calendar-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 10px 5px 15px;
  }

  .calendar-navigation__date {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .calendar-navigation__date-btn {
    height: 30px;
    margin-right: 10px;
    padding: 0 7px;
    border: 1px solid #a7abbd;
    border-radius: 3px;
    color: #7a829d;
    line-height: 28px;
    text-align: center;
  }

  .calendar-navigation__date-btn:hover {
    background-color: #f4f6f8;
  }

  .calendar-navigation__date-btn--back,
  .calendar-navigation__date-btn--forward {
    width: 30px;
    margin: 0 5px;
    background-position: 50% 50%;
    background-size: 7px auto;
    background-repeat: no-repeat;
  }

  .calendar-navigation__date-btn--back {
    background-image: url(~@assets/imgs/arrows/arrow_to_left_grey.svg);
  }

  .calendar-navigation__date-btn--forward {
    background-image: url(~@assets/imgs/arrows/arrow_to_right_grey.svg);
  }

  .calendar-navigation__date-btn:active {
    background-color: #a7abbd;
    color: #fff;
  }

  .calendar-navigation__date-btn--back:active {
    background-image: url(~@assets/imgs/arrows/arrow_to_left_grey_active.svg);
  }

  .calendar-navigation__date-btn--forward:active {
    background-image: url(~@assets/imgs/arrows/arrow_to_right_grey_active.svg);
  }

  .calendar-navigation__date-btn:focus {
    border: solid 1px #3af;
    box-shadow: 0 0 1px #3af;
  }

  .calendar-navigation__view {
    display: flex;
  }

  .calendar-navigation__view-btn {
    padding: 10px 6px;
  }

  .calendar-navigation__view-btn:focus {
    box-shadow: 0 0 2px 1px #3af;
  }

  .calendar-navigation__view-btn:hover {
    box-shadow: none;
    opacity: 0.6;
  }

  .calendar-navigation__view-btn:active {
    box-shadow: none;
    opacity: 1;
  }

  .calendar-navigation__view-btn.is-active {
    font-weight: bold;
  }

  @media (max-width: 767px) {
    .calendar-navigation__view {
      display: none;
    }
  }
</style>
